import React, { useContext, useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { LoginContext } from "./LoginContext";

const LoginPopup = () => {
  const { googleUser, renderLoginButton, handleLogin} = useContext(LoginContext);
  const [notificationsDisabled, setNotificationsDisabled] = useState(false);
  const [userXPressed, setUserXPressed] = useState(null);

  useEffect(() => {
    if (Notification.permission === "denied") {
      setNotificationsDisabled(true)
    }
    else {
      setNotificationsDisabled(false)
    }
  }, []);

  useEffect(() => {
    //  Button("popupGoogleLoginButton")
  }, []);

  return (

    <div className={`w-full h-full absolute z-30 bg-slate-300 bg-opacity-50 flex flex-col items-center ${((googleUser !== null && notificationsDisabled === false) || userXPressed) ? 'hidden' : ''}`}>

      <div className='flex flex-col p-2 bg-slate-100 border-2 border-slate-300 bg-opacity-95 w-[70%] h-[40%] rounded-lg'  style={{ 'marginTop': '20vh' }}>

        <button onClick={() => { setUserXPressed(true) }} className="text-slate-400 w-full relative flex justify-start px-4 py-2" style={{ 'fontSize': '3vh' }}>X</button>

        <div className='flex flex-col h-[100%] justify-center items-cente'  style={{ 'marginTop': '-6vh' }}>

          <p className='tracking-wide font-bold pt-4' style={{ 'fontSize': '2.5vh' }}> שבת שלום ❤️😇 </p>

          {googleUser === null ? (
            <div className="pb-4">
              <p className="pt-4" style={{ 'fontSize': '2vh' }}>כדי שנוכל לשלוח לך התראות לפני כניסת שבת, מומלץ להתחבר עם חשבון גוגל</p>
              
              {<button onClick={handleLogin} className="font-semibold flex m-auto px-4 py-1 mt-4 border gap-2 items-center bg-blue-600  border-slate-200 rounded-lg text-slate-200" style={{ 'fontSize': '2vh' }}>
                <FcGoogle />
                התחבר עם גוגל
              </button>}
            </div>
          )
            :

            notificationsDisabled === true && (
              <p className="pt-4" style={{ 'fontSize': '2vh' }}>כדי שנוכל לשלוח לך התראות לפני כניסת שבת, מומלץ לאפשר התראות בהגדרות הטלפון שלך עבור אפליקציה זו ⚙️</p>
            )
          }


        </div>
      </div>

    </div>

  );
};

export default LoginPopup;
