import { useContext, } from "react";
import { LoginContext } from "../components/LoginContext";

const About = () => {
  //const { googleUser, handleLogin, handleLogout} = useContext(LoginContext);
  const textStyle = { "textShadow": "0 0 4px #ffffff, 0 0 8px #ffffff, 0 0 12px #ffffff, 0 0 16px #ffffff" }
  const {handleLogout} = useContext(LoginContext);

  return (

    <div className='bg-[#ffffff80] shadow-lg w-[90%] h-[90%] flex flex-col items-center gap-6 overflow-y-auto px-2'  style={{ fontSize: '2.4vh' }}>

    {/*<button onClick={() => {logger.log("AAA"); handleLogout()}} className="font-semibold flex m-auto px-4 py-2 border gap-2 items-center bg-blue-600  border-slate-200 rounded-lg text-slate-200">Disconnect from google</button>
    */}
      <h1 style={textStyle} className='tracking-wide mt-16 font-bold text-3xl pt-4'> קצת עלינו </h1>
      

      <p style={textStyle}>
      ברוכים הבאים ל"שבת שלי"
      <br/><br/>
      ב"שבת שלי" אנו שואפים לשפר את אווירת החג והשבת כמה שרק אפשר. 
      <br/>
      באפליקציה תוכלו להתעדכן בקלות בזמני כניסת ויציאת השבת, 
      לקבל תזכורת אישיות לפני כניסת השבת וזמני הדלקת נרות,  
      ולהעדכן על פרשת השבוע וקצת על השבוע הקרב ובא. 
      <br/><br/>
      מקווים שתהנו מהשימוש באפליקציה ושתחוו את השבת בצורה הטובה ביותר בשבילכם <br/>
      שבת שלום לכולנו <br/>
      צוות ZwiClick ❤️😇<br/>
      <br/>
      <span className="text-base">

      <span
      onClick={() => handleLogout()}
    >
      לתגובות
    </span>

      , הצעות נוספות ורעיונות נשמח שתיצרו איתנו קשר <br/>
      <a href="mailto:info@ZwiClick.com" className="underline">info@zwiclick.com</a><br/>

      </span>
      </p>

    </div>
  )
}

export default About