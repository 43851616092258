import React, { useState, useEffect } from 'react';

import call_backend_api from '../components/APIHandler';
import { CircularProgress } from '@mui/material';

const ParashatHashavua = () => {
  const textStyle = { "textShadow": "0 0 4px #ffffff, 0 0 8px #ffffff, 0 0 12px #ffffff, 0 0 16px #ffffff" }

  const [parashaData, setParashaData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    call_backend_api("get_shabat_info_record", "GET", {}, setLoading)
    .then((backend_reponse) => {
      setParashaData(backend_reponse['shabat_record']['parasha_record'])
    });
  }, []);

  return (

    <div className='overflow-y-auto bg-[#ffffff80] shadow-lg w-[90%] h-[90%] flex flex-col items-center gap-6 pb-6' style={{ fontSize: '2.6vh' }}>
  
      {loading && (
        <div className='flex flex-col bg-opacity-0 absolute z-50 items-center justify-center w-full h-full -mt-48'>
          <CircularProgress style={{ color: '#2b8b9c' }}
            size={60} 
            thickness={5} />
        </div>
        ) 
      }
      <h1 className='tracking-wide mt-16 font-bold text-3xl py-4'> פרשת השבוע </h1>

      <h2 className='py-[0.5] text-1xl w-[90%] bg-[#ffffffa0] rounded-md'> שבוע "{parashaData['name']}" </h2>
      <p className='px-6' style={textStyle}>
        {parashaData['insperational']} 
        <br></br>
      </p>
      <h2 className='py-[0.5] text-1xl w-[90%] scroll-m-96 bg-[#ffffffa0] rounded-md'> סיפור "{parashaData['name']}" </h2>
      <p className='px-6' style={textStyle}>

        {parashaData['summary']} </p>
    </div >


  )
}

export default ParashatHashavua